<template>
  <div class="payment-container" v-bind:style="{backgroundColor: '#ffff', minHeight: '665px', padding: '20px'}">
    <div id="dropin-container" v-bind:style="{margin: 'auto', maxWidth: '700px'}"></div>
  </div>
</template>

<script>

import AdyenCheckout from '@adyen/adyen-web';

var vm = null

export default {
  data() {
    vm = this;
    
    return {
      paymentMethods: [],
      amount: 0,
      currency: null,
      client_key: null,
      shopperLocale: null
    }
  },
  methods: {
    filterPaymentMethods(pm) {
      pm.paymentMethods = pm.paymentMethods.filter((it) =>
        [
          'scheme'
        ].includes(it.type)
      );
      return pm
    },
    showFinalResult(response,force_redirect=false) {
      let msg = ''
      switch (response.resultCode) {
        case vm.$const.ADYEN.RESULT_CODE.AUTHORISED:
          msg = vm.$t('adyen.msg_authorised')
          break
        case vm.$const.ADYEN.RESULT_CODE.CANCELLED:
          msg = vm.$t('adyen.msg_cancelled')
          break
        case vm.$const.ADYEN.RESULT_CODE.ERROR:
          msg = vm.$t('adyen.msg_error')
          break
        case vm.$const.ADYEN.RESULT_CODE.PENDING:
          msg = vm.$t('adyen.msg_pending')
          break
        case vm.$const.ADYEN.RESULT_CODE.RECEIVED:
          msg = vm.$t('adyen.msg_received')
          break
        case vm.$const.ADYEN.RESULT_CODE.REFUSED:
          msg = vm.$t('adyen.msg_refused')
          break
        default:
          break
      }

      if(response.resultCode !== vm.$const.ADYEN.RESULT_CODE.AUTHORISED) {
        vm.$notify({
          type: 'error',
          title: msg
        })
      }

      if(force_redirect || response.resultCode === vm.$const.ADYEN.RESULT_CODE.AUTHORISED) {
        this.$router.push({
            name: 'payment-status',
            params: {
              transaction: vm.transaction
            }
          })
      }
    },
    createAdyenObject() {
      const translations = {
        'es-MX': {
          "billingAddress": "Dirección de la tarjeta",
        }
      }
      const configuration = {
        paymentMethodsResponse: vm.filterPaymentMethods(vm.paymentMethods), // The `/paymentMethods` response from the server.
        clientKey: vm.client_key, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
        locale: vm.shopperLocale,
        translations: translations,
        environment: vm.$const.ADYEN.ENVIRONMENT.LIVE,
        amount: {
          value: vm.amount * 100,
          currency: vm.currency
        },
        onSubmit: (state, dropin) => {
          dropin.setStatus('loading')
          // Global configuration for onSubmit
          // Your function calling your server to make the `/payments` request
          let paymentData = {
            paymentMethod: state.data.paymentMethod,
            transaction: vm.transaction,
            browserInfo: state.data.browserInfo,
            billingAddress: state.data.billingAddress
          }

          vm.axios.apiPayment.makeAdyenPayment(paymentData, function(response){
            if (response.action) {
                // Drop-in handles the action object from the /payments response
                dropin.handleAction(response.action);
              } else {
                vm.showFinalResult(response);
                dropin.setStatus('ready');
              }
          }, function(response) {
            dropin.setStatus('ready');
            throw Error(error);
          })
        },
        onAdditionalDetails: (state, dropin) => {
          dropin.setStatus('loading');
          // Your function calling your server to make a `/payments/details` request
          let additionalData = {
            data: state.data,
            transaction: vm.transaction
          }
          
          vm.axios.apiPayment.makeAdyenPaymentDetails(additionalData, function(response){
              if (response.action) {
                  // Drop-in handles the action object from the /payment/details response
                  dropin.handleAction(response.action);
                } else {
                  vm.showFinalResult(response);
                  dropin.setStatus('ready');
                }
            }, function(response) {
              dropin.setStatus('ready');
              throw Error(error);
            })
        },
        paymentMethodsConfiguration: {
          card: { 
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: false,
            hideCVC: false, 
            name: vm.$t('adyen.credit_or_debit_card'),
            billingAddressRequired: true
          },
          threeDS2: { 
              challengeWindowSize: '05'
          },
        },
        showPayButton: true
      }
    
      const checkout = new AdyenCheckout(configuration)
  
      const dropin = checkout
          .create('dropin', {
            openFirstPaymentMethod:true
          })
        .mount('#dropin-container')
    },
    getBrowserInfo() {
      return {
        acceptHeader: "*/*",
        colorDepth: window.screen.colorDepth,
        javaEnabled: window.navigator.javaEnabled(),
        javaScriptEnabled: true,
        language: this.shopperLocale,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        timeZoneOffset: (new Date()).getTimezoneOffset(),
        userAgent: navigator.userAgent
      }
    },
    handlleInitPaymentData() {
      if (this.$route.params.transaction) {
        vm.transaction = this.$route.params.transaction
      }
    
      if (this.$route.params.payment_url) {
        var paymentUrl = decodeURIComponent(atob(this.$route.params.payment_url))
      }
        
      vm.shopperLocale = this.$session.get('lang') === vm.$const.LANG_ES ? vm.$const.ADYEN.LANGUAGE_CODE.ES : vm.$const.ADYEN.LANGUAGE_CODE.EN 

      var params = paymentUrl.split('?')[1].split('&')
      for (var param in params) {
        if (params[param].includes('amount')) {
          vm.amount = params[param].split('=')[1]
        }

        if (params[param].includes('currency')) {
          vm.currency = params[param].split('=')[1]
        }

        if (params[param].includes('client_key')) {
          vm.client_key = params[param].split('=')[1]
        }
      }

      if(!vm.client_key || !vm.transaction || !vm.amount || vm.amount <= 0) {
        alert('Payment Data is invalid')
        window.history.back()
      }

      vm.axios.apiPayment.getPaymentStatus(vm.transaction,
        function (response) {
          if(response.data.data.item.status !== 0) {
            vm.$router.push({ 'name': 'checkout'})
          }
        }
      )
    },
    handleRedirectResult() {
      vm = this
      vm.transaction = vm.$route.query.transaction;
      let data = {
        transaction: vm.transaction,
        redirectResult : vm.$route.query.redirectResult
      };
      vm.axios.apiPayment.handleAdyenRedirectResult(data, function(response) {
         vm.showFinalResult(response, true);
      }, function(error) {
        throw Error(error);
      })
    }
  },
  created() {
    if(this.$route.query.redirectResult) {
      this.handleRedirectResult();
    } else {
      this.handlleInitPaymentData();
    }
  },
  mounted() {
    vm = this
   
   if(!vm.$route.query.redirectResult) {
      let params = {
        amount: vm.amount,
        currency: vm.currency,
        locale: vm.shopperLocale
      }

      vm.axios.apiPayment.getAdyenPaymentMethods(params, 
        function(response){
          vm.paymentMethods = response.data.data.item
          vm.createAdyenObject()

          const link = document.createElement("link")
          link.rel = "stylesheet"
          link.href = vm.$const.ADYEN.STYLE_LINK
        
          document.head.appendChild(link);
        })
    }
  }
}
</script>
